import clsx from "clsx";
import Image, { StaticImageData } from "next/image";
import { FC, ReactNode } from "react";

type CoverageTypeCardProps = {
  img: StaticImageData;
  title: string | ReactNode;
  alt?: string;
  description: string;
  children?: ReactNode;
};

const CoverageTypeCard: FC<CoverageTypeCardProps> = ({
  img,
  title,
  description,
  alt,
  children,
}) => {
  return (
    <div
      className={clsx(
        "py-12",
        "px-10",
        "bg-white",
        "text-center",
        "flex",
        "flex-col",
        "justify-start",
        "gap-4",
        "items-center",
        "rounded-2xl",
        "border"
      )}
    >
      <Image
        className={clsx("object-contain")}
        src={img.src}
        width={160}
        height={160}
        alt={alt ? alt : typeof title === "string" ? title : ""}
      />
      <h4 className={clsx("text-2xl", "font-bold")}>{title}</h4>
      <p className={clsx("text-base")}>{description}</p>
      {children}
    </div>
  );
};

export default CoverageTypeCard;
