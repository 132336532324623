import React, { useState, useEffect } from "react";
import Head from "next/head";
import clsx from "clsx";
import { ApplicationProvider } from "context/app";
import Header from "components/common/Header";
import CoverageTypeChecker from "components/pages/coverageTypeChecker";
import useGetUserContinuity from "hooks/useGetUserContinuity";
import { CurrentSelfOnboardingSession } from "types/context";
import { CoverageType } from "utils/constants/generic";
import { ROUTES } from "utils/constants/routes";
import { useRouter } from "next/router";
import English from "locale/english.json";

type CoverageTypeCheckerProps = {
  session: CurrentSelfOnboardingSession | null;
};

export default function Home() {
  const { query, isReady } = useRouter();
  const [session, setSession] = useState<CurrentSelfOnboardingSession | null>(
    null
  );
  const [toastMsg, setToastMsg] = useState<string | null>(null);

  const { mutate } = useGetUserContinuity();

  useEffect(() => {
    if (!isReady) {
      return;
    }

    const typeString = query.type as string;

    if (!typeString) {
      return;
    }
  }, [isReady, query.type]);

  useEffect(() => {
    mutate(undefined, {
      onSuccess: ({ session }: CoverageTypeCheckerProps) => {
        setSession(session);
      },
    });
  }, [mutate]);

  return (
    <div>
      <Head>
        <title>Get your Free Quote | GetAddify</title>
        <meta name="description" content="GetAddify, Get your Free Quote" />
        <link rel="icon" href="/favicon.png" />
      </Head>

      <div className={clsx("grid grid-rows-[auto_auto_1fr] min-h-screen")}>
        <Header />
        {toastMsg && (
          <div
            className={clsx(
              "p-4",
              "my-4",
              "mx-auto",
              "text-md",
              "text-center",
              "max-w-xl",
              "text-yellow-800",
              "rounded-lg",
              "bg-yellow-50",
              "dark:bg-gray-800",
              "dark:text-yellow-300"
            )}
          >
            {toastMsg}
          </div>
        )}
        <div className={clsx("flex", "justify-center")}>
          <CoverageTypeChecker session={session} />
        </div>
      </div>
    </div>
  );
}

Home.provider = ApplicationProvider;
