import { FC, useState } from "react";
import { useFormik } from "formik";
import clsx from "clsx";
import { Button, Input, yup } from "@getaddify/lux";
import Modal, { ModalProps } from "components/common/Modal";
import { CoverageType } from "utils/constants/generic";
import { UNDERWRITING_RESTRICTIONS } from "utils/constants";
import IndeterminateLoading from "components/common/Loading";
import English from "locale/english.json";
import { useRouter } from "next/router";
import { API_ROUTES, ROUTES } from "utils/constants/routes";
import { SwitchRequest, SwitchResponse } from "types/switch";
import { api } from "utils/api";
import { SetUser } from "utils/analytics";

type CoverageSwitchModalProps = Pick<ModalProps, "isOpen" | "onClose"> & {
  accountId: string;
  desiredCoverage: CoverageType;
  existingCoverage: CoverageType;
  setIsLoading: (isLoading: boolean) => void;
};

const validationSchema = yup.object({
  ein: yup
    .string()
    .required(English.validation.required)
    .ein(English.validation.ein),
  companyFleetSize: yup
    .number()
    .required(English.validation.required)
    .integer(English.validation.fleet_size_whole)
    .min(
      UNDERWRITING_RESTRICTIONS.CONTINUOUS_COVERAGE_MIN_VEHICLES,
      English.validation.fleet_size_min_cc.replace(
        "${min_fleet_size}",
        UNDERWRITING_RESTRICTIONS.CONTINUOUS_COVERAGE_MIN_VEHICLES.toString()
      )
    ),
});

type CoverageSwitchFormikValues = yup.InferType<typeof validationSchema>;

const initialValues: CoverageSwitchFormikValues = {
  ein: "",
  companyFleetSize: 0,
};

const CoverageSwitchModal: FC<CoverageSwitchModalProps> = ({
  accountId,
  isOpen,
  onClose,
  setIsLoading,
  desiredCoverage,
  existingCoverage,
}) => {
  const router = useRouter();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (values: CoverageSwitchFormikValues) => {
    await handleSwitch(values);
  };

  const handleSubmit = async () => {
    if (desiredCoverage === CoverageType.ContinuousCoverage) {
      submitForm();
    } else {
      await handleSwitch();
    }
  };

  const handleSwitch = async (values?: CoverageSwitchFormikValues) => {
    setLoading(true);
    const switchResponse = await api<SwitchRequest, SwitchResponse>(
      `${API_ROUTES.SwitchCoverage}/${accountId}`,
      "POST",
      {
        desiredCoverage,
        ...values,
      }
    );

    setLoading(false);

    if (
      !switchResponse ||
      !switchResponse.status ||
      !switchResponse.status.success
    ) {
      setError(English.switch.general_error);
      console.error(
        "SWITCH::",
        switchResponse?.status?.error || English.errors.problem
      );
      return;
    }

    SetUser(switchResponse.user || null);

    await routeToContinue();
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    resetForm,
    submitForm,
  } = useFormik<CoverageSwitchFormikValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const coverageTypeLabel = (coverageType: CoverageType) => {
    switch (coverageType) {
      case CoverageType.ContinuousCoverage:
        return English.coverage_type.continuous_coverage;
      case CoverageType.ORP:
        return English.coverage_type.orp;
    }
  };

  const info = (
    <p className={clsx("max-w-md", "text-center", "mx-auto", "my-8")}>
      {English.switch.info}
    </p>
  );

  const setLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
    setIsProcessing(isLoading);
  };

  const setClose = () => {
    if (!isProcessing) {
      onClose();
    }
  };

  const routeToContinue = async () => {
    setClose();
    await router.push(`${ROUTES.CONTINUE}/${accountId}`);
  };

  return (
    <Modal
      displayHeader={false}
      isOpen={isOpen}
      onClose={setClose}
      size="max-w-2xl"
    >
      {!isProcessing && (
        <>
          <h2 className={clsx("title")}>{English.switch.modal_title}</h2>
          <hr className="my-4" />
        </>
      )}
      {isProcessing && (
        <div>{IndeterminateLoading(300, English.states.processing)}</div>
      )}
      {!isProcessing && (
        <>
          {desiredCoverage === CoverageType.ContinuousCoverage && (
            <>
              <h4 className={clsx("mb-8", "italic")}>
                {English.switch.need_more}
              </h4>
              <form onReset={() => resetForm()} onSubmit={handleSubmit}>
                <Input
                  name="ein"
                  label="EIN/FEIN number"
                  required={true}
                  type="numberGeneric"
                  helperText={errors && errors.ein}
                  {...{
                    values,
                    errors,
                    onBlur: handleBlur,
                    onChange: handleChange,
                    touched,
                  }}
                />
                <br />
                <Input
                  type="number"
                  name="companyFleetSize"
                  label="Fleet size"
                  min={
                    UNDERWRITING_RESTRICTIONS.CONTINUOUS_COVERAGE_MIN_VEHICLES
                  }
                  step={1}
                  required={true}
                  helperText={errors && errors.companyFleetSize}
                  {...{
                    values,
                    errors,
                    onBlur: handleBlur,
                    onChange: handleChange,
                    touched,
                  }}
                />
              </form>
              {info}
            </>
          )}
          {desiredCoverage === CoverageType.ORP && <>{info}</>}
          {error && (
            <p
              className={clsx(
                "max-w-md",
                "text-center",
                "mx-auto",
                "my-8",
                "text-red-500"
              )}
            >
              {error}
            </p>
          )}
          <div className={clsx("flex", "items-center", "justify-end", "gap-4")}>
            <Button type="secondary" className="w-full" onClick={handleSubmit}>
              {English.switch.change_to} {coverageTypeLabel(desiredCoverage)}
            </Button>
            <Button type="primary" className="w-full" onClick={routeToContinue}>
              {English.switch.continue_with}{" "}
              {coverageTypeLabel(existingCoverage)}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default CoverageSwitchModal;
